'use strict';

var $ = window.jQuery = require('jquery');
var smoothscroll = require('smoothscroll-polyfill');

function handleScroll() {
  var scrollTop = $(document).scrollTop();
  var navBar = $('#navigation-bar-wrapper');
  var backToTopButton = $('#back-to-top');
  if (scrollTop > (navBar.outerHeight() + navBar.outerHeight())) {
    if (!navBar.hasClass('slide-out')) {
      navBar.addClass('slide-out');
      $('#navigation-bar-dropdown').addClass('fixed');
    }
    if (scrollTop > ((navBar.outerHeight() + navBar.outerHeight()) + 100)) {
      if (!navBar.hasClass('animate-top')) {
        navBar.addClass('animate-top');
      }
      if (scrollTop > ($(window).innerHeight() - 200)) {
        if (!navBar.hasClass('slide-in')) {
          navBar.addClass('slide-in');
        }
      } else {
        if (navBar.hasClass('slide-in')) {
          navBar.removeClass('slide-in');
        }
      }
    } else {
      if (navBar.hasClass('animate-top')) {
        navBar.removeClass('animate-top');
      }
    }
  } else {
    if (navBar.hasClass('slide-out')) {
      navBar.removeClass('slide-out');
      $('#navigation-bar-dropdown').removeClass('fixed');
    }
  }
  if (scrollTop > ($(window).innerHeight() * 2)) {
    backToTopButton.addClass('show-button');
  } else {
    backToTopButton.removeClass('show-button');
  }
}

function toggleDropdownMenu() {
  var navBarWrapper = $('#navigation-bar-wrapper');
  var navBarDropdown = $('#navigation-bar-dropdown');
  var html = $('html');
  if (navBarWrapper.hasClass('open')) {
    navBarWrapper.removeClass('open');
    navBarDropdown.removeClass('open');
    html.removeClass('disable-scroll');
  } else {
    navBarWrapper.addClass('open');
    navBarDropdown.addClass('open');
    html.addClass('disable-scroll');
  }
}

function scrollToSection(section) {
  var y = ($('#' + section).offset().top - $('#navigation-bar-wrapper').outerHeight());
  window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth'
  });
}

function showMore(section) {
  var itemWrapper = $('#' + section + ' .items-wrapper');
  itemWrapper.css('max-height', parseInt(itemWrapper.css('max-height')) * 2);
}

function initHandlers() {
  /*$('body').on('touchstart', function(e){
    if ($('body').hasClass('noscroll')) {
      e.preventDefault();
    }
  });*/

  $(window).on('scroll', function() {
    handleScroll();
  });

  // Mobile back to top button
  $('#back-to-top').on('click', function() {
    scrollToSection('hero');
  });

  // Burger menu
  $('.burger-menu-toggle').on('click', function() {
    toggleDropdownMenu();
  });

  // Navbar
  $('.navigation-bar-link').on('click', function() {
    var section = $(this).attr('data-section');
    if (section) {
      scrollToSection(section);
      if (window.innerWidth < 768) {
        toggleDropdownMenu();
      }
    }
  });

  // Hero scroll down button
  $('#scroll-hero').on('click', function() {
    scrollToSection('what-we-do');
  });

  // Generic show more button
  $('.show-more').on('click', function() {
    showMore($(this).attr('data-section'));
  });
}

$(function() {
  smoothscroll.polyfill();
  initHandlers();
});
